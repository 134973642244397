<template>
  <div class="surface_container">
    <img src="../assets/images/surface1.png" alt="" class="banner">
    <h2>心理量表</h2>
	<div class="consulting_service_inner m_1200">
		  <div class="search_box">
		    <span class="label">全部</span>
		    <div class="input_wrap">
		      <el-input placeholder="请输入搜索内容" v-model.trim="keyword" class="input-with-select" clearable>
		        <el-button slot="append" icon="el-icon-search" @click="getList">搜索</el-button>
		      </el-input>
		    </div>
		  </div>
	</div>
    <ul class="list_box m_1200">
      <div class="list_item" v-for="(item,index) in listData" :key="index" @click="getDetai(item)">
        <img :src="item.img" alt="" class="photo">
        <div class="right">
          <p class="title">{{item.title}}</p>
          <p class="content">￥{{item.price}}</p>
          <p class="detail">查看详情</p>
        </div>
      </div>
    </ul>
    <div class="page_box m_1200">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[20, 30, 40, 50, 100]"
        :current-page="pageNo"
        :page-size="pageSize"
        :background='true'
        :small="device"
        :layout="device ? 'prev, pager, next' : 'total, sizes, prev, pager, next, jumper'"
        :total="total"
        >
      </el-pagination>
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex' 
export default {
  data () {
    return {
      pageNo: 1,//页码
      pageSize: 20,//页数
      total: 0,//总数
      listData: [],//列表数据
	  keyword:''
    }
  },
  computed: {
    ...mapState(['device'])
  },
  mounted () {
  	this.getList()
  },
  methods: {
	getList(){
		this.$http({
		  method: 'POST',
		  url: '/News/news',
		  data: {pageSize:this.pageSize,pageIndex:this.pageIndex,cate_id:13,keyword:this.keyword}
		}).then((res)=>{
			this.listData=res.data
			this.total=res.count
		})
	},
	// 查看详情
	getDetai(item){
		this.$router.push({path:'/surfaceDetail',query:{id:item.id}})
	},
    //页数发生改变
    handleSizeChange(val) {
      this.pageSize = val
	  this.getList()
    },
    //页码发生改变
    handleCurrentChange(val) {
      this.pageIndex = val
	  this.getList()
    }
  }
}
</script>

<style lang="scss">
.surface_container {
  padding-bottom: 60px;
  background-color: #F7F8FC;
  .search_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    padding: 15px 0;
    .label {
      display: none;
      padding-bottom: 6px;
      font-size: 12px;
      font-weight: bold;
      border-bottom: 3px solid #E51418;
    }
    .el-input-group__append {
      background-color: #E51418;
      color: #ffffff;
      border-color: #E51418;
    }
    .input_wrap {
      width: 360px;
    }
  }
  .banner {
    display: block;
    width: 878px;
    height: 216px;
    margin: 0 auto;
    padding: 20px 0;
  }
  h2 {
    margin-bottom: 40px;
    color: #2C3657;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
  }
  .list_box {
	  display: flex;
	  flex-wrap: wrap;
    .list_item {
		width:7%;
      padding:10px;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0 0 15px rgba(0,0,0,.1);
      cursor: pointer;
	  margin-right: 1%;
	  margin-bottom: 10px;
      .photo {
        width:  100%;
        height: 80px;
        margin-right: 53px;
      }
    }
    .right {
      overflow: hidden;
      flex: 1;
      .date {
        margin-bottom: 12px;
        color: #2C3657;
        font-size: 16px;
        font-weight: bold;
        text-decoration: underline;
      }
      .title {
        margin-bottom: 5px;
        color: #2C3657;
        font-size: 14px;
        font-weight: bold;
		margin-top:10px;
      }
      .content {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: #2C3657;
        font-size: 14px;
        line-height: 1.5;
      }
      .detail {
        box-sizing: border-box;
        width: 100%;
        height: 30px;
        margin-top: 10px;
        color: #E51418;
        font-size: 14px;
        text-align: center;
        line-height: 30px;
        border: 1px solid #E51418;
        border-radius: 4px;
      }
    }
  }
  .page_box {
    padding: 60px 0 0;
    text-align: right;
  }
}
@media screen and (max-width: 768px) {
  .surface_container {
    padding-bottom: 42px;
    background-color: #F7F8FC;
    .banner {
      display: block;
      width: 100%;
      height: 160px;
      margin: 0 auto;
      padding: 20px 0;
    }
    h2 {
      margin-bottom: 20px;
      color: #2C3657;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    }
    .list_box {
      box-sizing: border-box;
      padding: 0 15px;
      .list_item {
        box-sizing: border-box;
        padding: 15px;
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 0 0 15px rgba(0,0,0,.1);
		width: 100%;
        cursor: pointer;
        &+.list_item {
          margin-top: 12px;
        }
        .photo {
          width:  100%;
          height: 132px;
		  margin-bottom: 10px;
        }
      }
      .right {
        overflow: hidden;
        flex: 1;
        .date {
          margin-top: 0px;
          margin-bottom: 10px;
          color: #2C3657;
          font-size: 16px;
          font-weight: bold;
          text-decoration: underline;
        }
        .title {
          margin-bottom: 8px;
          color: #2C3657;
          font-size: 12px;
          font-weight: bold;
        }
        .content {
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          color: #2C3657;
          font-size: 12px;
          line-height: 1.5;
        }
        .detail {
          box-sizing: border-box;
          width: 80px;
          height: 24px;
          margin-top: 13px;
          color: #E51418;
          font-size: 12px;
          text-align: center;
          line-height: 24px;
          border: 1px solid #E51418;
          border-radius: 4px;
        }
      }
    }
    .page_box {
      padding: 24px 0;
      text-align: center;
    }
  }
}
</style>
